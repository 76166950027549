@font-face {
  font-family: 'maporium';
  src: url('../font/maporium.eot?78082611');
  src: url('../font/maporium.eot?78082611#iefix') format('embedded-opentype'),
  url('../font/maporium.woff2?78082611') format('woff2'),
  url('../font/maporium.woff?78082611') format('woff'),
  url('../font/maporium.ttf?78082611') format('truetype'),
  url('../font/maporium.svg?78082611#maporium') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'maporium';
    src: url('../font/maporium.svg?78082611#maporium') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "maporium";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-smap-icon-plan-explorer:before {
  content: '\21';
}

/* '!' */
.icon-smap-icon-plan-creator:before {
  content: '\22';
}

/* '"' */
.icon-smap-icon-plan-publisher:before {
  content: '\23';
}

/* '#' */
.icon-smap-icon-plan-analyst:before {
  content: '\24';
}

/* '$' */
.icon-smap-icon-plan-tester:before {
  content: '\25';
}

/* '%' */
.icon-smap-icon-role-manager:before {
  content: '\26';
}

/* '&' */
.icon-smap-icon-role-editor:before {
  content: '\27';
}

/* ''' */
.icon-smap-icon-role-viewer:before {
  content: '\28';
}

/* '(' */
.icon-smap-icon-role-commenter:before {
  content: '\29';
}

/* ')' */
.icon-smap-icon-role-owner:before {
  content: '\2a';
}

/* '*' */
.icon-smap-icon-account-individual:before {
  content: '\2b';
}

/* '+' */
.icon-smap-icon-account-community:before {
  content: '\2c';
}

/* ',' */
.icon-smap-icon-account-enterprise:before {
  content: '\2d';
}

/* '-' */
.icon-smap-icon-service-curation:before {
  content: '\2e';
}

/* '.' */
.icon-smap-icon-service-training:before {
  content: '\2f';
}

/* '/' */
.icon-smap-icon-service-integration:before {
  content: '\30';
}

/* '0' */
.icon-smap-icon-service-certification:before {
  content: '\31';
}

/* '1' */
.icon-smap-icon-linkage-all:before {
  content: '\32';
}

/* '2' */
.icon-smap-icon-linkage-select:before {
  content: '\33';
}

/* '3' */
.icon-smap-icon-linkage-none:before {
  content: '\34';
}

/* '4' */
.icon-smap-icon-object-smap:before {
  content: '\35';
}

/* '5' */
.icon-smap-icon-object-style:before {
  content: '\36';
}

/* '6' */
.icon-smap-icon-symbol-warning:before {
  content: '\37';
}

/* '7' */
